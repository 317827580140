import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Box,
    Typography, 
} from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import {CommonWidgetLayout} from "../shared/CommonWidgetLayout";
import {useResizeObservedRef} from "../../../_helpers/useResizeObservedRef";
import LegendItem from "./LegendItem";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.common,
    ...theme.widget.ticketAttendanceChart,
    ...theme.widget.trafficLight,
    container: { ...theme.widget.ticketAttendanceChart.container },    
  }));

const BASE_FONT_SIZE = 10;

export const WidgetTicketAttendanceChartContent = ({width, height, data, settings, viewMode, brandingSettings, onAudioPlay}) => {
    const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
    const [xPadding, setXPadding] = useState(16);
    const [yPadding, setYPadding] = useState(16);
    const [scaleFactor, setScaleFactor] = useState(1.0);

    const classes = useStyles({ width, fontSize, scaleFactor, ...brandingSettings });

    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  
    const ref = useResizeObservedRef(({ width, height }) => {
        setXPadding( Math.min( Math.max(16, 0.05 * width), 64));
        setYPadding( Math.min( Math.max(4, 0.01 * height), 32));
        setScaleFactor( Math.min(width / 330, height / 75));
    });

    const bars =[];
    //if (data) data.ticketScans = 25; // This is just a fake number, for testing.

    if (
      !isViewMode ||
      !data ||
      (!data.expectedAttendance && /*!data.ticketSales && */ !data.ticketScans)
    ) {
      // All three metrics are 0s. Display a grey box instead of the chart.
      bars.push(
        <Box
          key="empty"
          sx={{ flexGrow: 1 }}
          className={`${classes.bar} ${classes.emptyBar}`}
        />
      );
    } else {
      const metrics = [/*'ticketSales',*/ "expectedAttendance", "ticketScans"];
      metrics.sort((metric1, metric2) => data[metric2] - data[metric1]); // Sort in descending order.
      const maxMetricValue = data[metrics[0]];
      metrics.forEach((metric) => {
        const proportion = data[metric] / maxMetricValue;
        bars.push(
          <Box
            key={metric}
            sx={{
              width: `${Math.min(100, Math.ceil(proportion * 100))}%`,
            }}
            style={{
              gridRow: 1,
              gridColumn: 1,
            }}
            className={`${classes[metric + "Bar"]} ${classes.bar}`}
          />
        );
      });
    }

    const legend = [];
    [
      { title: "Expected Attendance", property: "expectedAttendance" },
      { title: "Total Tickets Scans", property: "ticketScans" },
      //   { title: "Total sold", property: "ticketSales" },
    ].forEach((metric) => {
      legend.push(
        <LegendItem
          metric={metric.property}
          title={metric.title}
          value={isViewMode && data ? data[metric.property] : 0}
          scaleFactor={scaleFactor}
        />
      );
    });

    return (
        <Box   // This box provides border and dynamic padding for widget content.
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                height: '100%',
                width: '100%',
                padding: `${yPadding + 32}px ${xPadding}px ${yPadding}px ${xPadding}px`,
            }}
            className={classes.container}
            ref={ref}
        >
            <Box  // This is a grid box that contains the chart.
                sx={{
                    display: 'grid',
                    flexGrow: 1,
                }}>
                {bars}
            </Box>

            <Box  // This row flexbox shows the legend.
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    justifyContent: 'center'
                }}
                className={classes.legendContainer}
            >
                {legend}
            </Box>

        </Box>
    );
};

export default WidgetTicketAttendanceChartContent;
